<template>
  <v-container fluid>
    <search-form v-if="false" @submit="handleSearchFormSubmit"/>
    <v-card class="mt-5">
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2 mr-3"
        >
          mdi-format-list-bulleted-square
        </v-icon>
        {{ $vuetify.lang.t('$vuetify.sosAlarm.list') }}
        <v-spacer></v-spacer>

      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="sosAlarmItems"
          item-key="id"
          hide-default-footer
        >

          <template v-slot:item.gender="{ item }">
            {{ $vuetify.lang.t(`$vuetify.gender.${ item.gender.toLowerCase() }`) }}
          </template>

          <template v-slot:item.alarmTime="{ item }">
            {{ item.alarmTime | moment('YYYY-MM-DD HH:mm') }}
          </template>

          <template v-slot:item.doctorName="{ item }">
            {{ item.doctorName }}( {{ item.doctorPhone }} )
          </template>

          <template v-slot:item.status="{ item }">
            {{ $vuetify.lang.t(`$vuetify.sosAlarmStatus.${ item.status.toLowerCase() }`) }}
          </template>

          <template v-slot:item.actions="{ item }">

            <v-btn
              v-if="item.status === 'READY'"
              class="m4-1"
              color="info"
              text
              small
              @click="handleDoItem(item)"
            >
              {{ $vuetify.lang.t('$vuetify.sosAlarm.progress') }}
            </v-btn>


            <v-btn
              v-else
              class="m4-1"
              color="info"
              text
              small
              @click="handleShowItem(item)"
            >
              {{ $vuetify.lang.t('$vuetify.sosAlarm.show') }}
            </v-btn>

          </template>

        </v-data-table>
        <v-pagination
          v-model="queryParams.params.page"
          :length="queryParams.params.pageCount"
        />
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="progressDialog"
      persistent
      max-width="600"
    >
      <do-form
        v-if="progressDialog"
        :edit-item="editItem"
        @submit="handleProgressDialogSubmit"
        @cancel="progressDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="900"
    >
      <sos-alarm-detail
        v-if="showDialog"
        :edit-item="editItem"
        @cancel="showDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      SearchForm: () => import('./SearchForm'),
      DoForm: () => import('./DoForm'),
      SosAlarmDetail: () => import('./Detail')
    },
    data () {
      return {
        selected: [],
        sosAlarmItems: [],
        progressDialog: false,
        showDialog: false,
        editItem: null,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        },
        form: {
          progressContent: null
        }
      }
    },

    computed: {

      ...mapGetters({
        sosAlarms: 'sosAlarm/sosAlarms',
        authUser: 'auth/user'
      }),

      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.common.name'),
            align: 'start',
            sortable: false,
            value: 'patientName',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.gender'),
            align: 'start',
            sortable: false,
            value: 'gender',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.phone'),
            align: 'start',
            sortable: false,
            value: 'patientPhone',
          },{
            text: this.$vuetify.lang.t('$vuetify.sosAlarm.address'),
            align: 'start',
            sortable: false,
            value: 'address',
          },{
            text: this.$vuetify.lang.t('$vuetify.sosAlarm.alarmTime'),
            align: 'start',
            sortable: false,
            value: 'alarmTime',
          },{
            text: this.$vuetify.lang.t('$vuetify.sosAlarm.status'),
            align: 'start',
            sortable: false,
            value: 'status',
          },{
            text: this.$vuetify.lang.t('$vuetify.sosAlarm.doctorName'),
            align: 'start',
            sortable: false,
            value: 'doctorName',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.actions'),
            align: 'start',
            sortable: false,
            value: 'actions',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.queryAllSosAlarm(params).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
              this.sosAlarmItems = this.sosAlarms
            })
         }
      }
    },

    created () {
      this.queryParams.params.institutionId = this.authUser.profile.depId
      let params = Object.assign({}, this.queryParams.params)
      params.page--
      this.queryAllSosAlarm(params).then( (res) => {
        this.queryParams.params.pageCount = res.totalPages
        this.sosAlarmItems = this.sosAlarms
      })

    },

    methods: {

      ...mapActions({
        queryAllSosAlarm: 'sosAlarm/queryAllSosAlarm',
        sosAlarmProgress: 'sosAlarm/progress'
      }),

      handleSearchFormSubmit(data) {
        this.queryParams.data = data
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.queryAllSosAlarm(params).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
          this.sosAlarmItems = this.sosAlarms
        })
      },


      handleDoItem (item) {
        this.editItem = item
        this.progressDialog = true
      },

      handleProgressDialogSubmit (data) {
        this.sosAlarmProgress({id: this.editItem.id, data: data}).then( () => {
          this.progressDialog = false
        })
      },

      handleShowItem (item) {
        this.editItem = item
        this.showDialog = true
      }
    }
  }
</script>
